<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="所属门店：" prop="shopId">
            <el-select clearable filterable v-model="form.shopId" placeholder="请选择" :disabled="mode === 'edit'">
              <el-option
                v-for="(item, index) in shopOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名：" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="性别：" prop="sex">
            <el-radio-group v-model="form.sex">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="身份证号：" prop="idCard">
            <el-input v-model="form.idCard"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="mobile">
            <el-input v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          <el-form-item v-if="mode === 'edit'" label="登录失败次数：" prop="loginfailure">
            <el-input v-model="form.loginfailure"></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-radio-group v-model="form.status">
              <el-radio v-for="(item, index) in EMPLOYEE_STATUS_OPTIONS" :key="index" :label="item.value">{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { EMPLOYEE_STATUS_OPTIONS } from "@/helpers/constants";
import { save, updateById } from "@/apis/unidrink/employee";
import { getShopOptions } from "@/apis/unidrink/shop";
import validators from "@/helpers/validator";
import { msgErr, msgSuc } from "@/helpers/message";
import { MD5 } from "crypto-js";
import { cloneDeep } from "lodash";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      EMPLOYEE_STATUS_OPTIONS:EMPLOYEE_STATUS_OPTIONS,
      initForm: {
        name: "",
        username: "",
        idCard: "",
        mobile: "",
        password: "",
        sex: "",
        status: 1,
      },
      form: {},
      shopOptions:[],
      rules: {
        shopId: [
          {
            required: true,
            message: "门店不能为空",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          validators.username,
        ],
        name: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          validators.password,
        ],
        sex: [
          { required: true, message: "性别不能为空", trigger: "blur" },
        ],
        idCard: [
          validators.IDcard,
        ],
        mobile: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
        ],
      },
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        getShopOptions(this.shopOptions)
        this.resetForm();
        this.editReflect();

        if (this.mode === "edit") {
          delete this.rules.password;

        } else {
          this.rules["password"] = [
            { required: true, message: "密码不能为空", trigger: "blur" },
            validators.password,
          ];
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
    
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
        this.form.password = "";
        this.form.shopId = Number.parseInt(this.form.shopId);
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let form = cloneDeep(this.form);
        if(form.password){
          form.password = MD5(form.password)+"";
        }
        const promise = this.mode === "edit" ? updateById : save;
        promise(form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
